import React from "react";
import { Link } from 'gatsby';
import { Translate } from "../../components/translations";
import "./styles.scss";

const Tools = (props: any) => {
  return (
    <div className={`list-element`}>
      <div className="list-info">
        <div className="list-item">
          <Link to={props.item.path}>
            <div className={`img-wrap ${props.item.path === '/ip-lookup' || props.item.path === '/domain-whois' ? 'small' : '' }`}>
              <img src={props.item.image} alt={props.item.name} />
            </div>

            <div className="text">
              <h4>
                <Translate name={props.item.name} />
              </h4>
              <p>
                <Translate name={props.item.subTitle} />
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Tools;
