import React from 'react';

import {PageHero} from '../../components/page-hero';
import ToolsList from '../../components/site-audit-tools-list';
import Section from '../../components/section';
import Layout from '../../components/layout/layout';
import {ToolsSliderItems} from '../../helpers/constants';
import Button from '../../components/button';

import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

const SiteAuditTools = () => (
  <Layout>
    <div className="content-wrapper">
      <PageHero title="Website audit tools" subtitle="Check your website with Hexometer’s free website audit tools." />
      <Section>
        <div style={{textAlign: 'center'}}>
          <p>
            Identify issues and test your website from different perspectives with our tech stack, broken links, meta
            tag analyzer, IP lookup, uptime monitoring, page speed, page performance, ssl analysis, whois domain lookup
            and W3C code validation tools.
          </p>
        </div>
        <ToolsList items={ToolsSliderItems} />
        <div className="tool-desc-wrap">
          <div className="row" style={{marginTop: '40px'}}>
            <div className="col-md-6" style={{margin: 'auto'}}>
              <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
            </div>
            <div className="col-md-6">
              <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
              <p>
                Harness the internet as your own data source, build your own scraping bots and leverage ready made
                automations to delegate time consuming tasks and scale your business.
              </p>
              <p>No coding or PhD in programming required.</p>
              <div>
                <Button
                  hexomaticCTA
                  className="monitor_btn"
                  onClick={() =>
                    (window.location.href = 'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                  }
                >
                  Get started in minutes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  </Layout>
);

export default SiteAuditTools;
