import React from "react"
import { SliderElem, SliderProps } from "../../types"
import Tools from "./tools"

const ToolsList = (props: SliderProps) => {
  return (
    <div className="list">
      {props.items.map((item: SliderElem, i: number) => (
        <Tools item={item} index={i} key={item.name} />
      ))}
    </div>
  )
}

export default ToolsList
